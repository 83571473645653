import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';


@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class OrderTimeModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: '365px',
                height: '260px',
                maxHeight: '300px'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
            }
        }
    }

    render() {
        const { modalIsOpen, closeModal, orderType } = this.props;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={closeModal}>
                <div className={style.Container}>
                    <div className={style.CancleXBtn}>
                        <span onClick={closeModal}>✕</span>
                    </div>
                    <div className={style.TextContnet}>
                        <div className={style.Title}>
                            <span>שים לב!</span>
                        </div>
                        <div className={style.TowRowText}>
                            <span>לא ניתן לבצע הזמנות</span>
                            <span>{orderType} לעכשיו</span>
                        </div>
                    </div>
                    <div className={style.ContinueBtn}>
                       <span onClick={closeModal}>חזור</span>
                    </div>

                </div>
            </Modal>
        )
    }
}

export default OrderTimeModal;