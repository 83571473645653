import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import NumberFormat from "react-number-format";
import ShippingOptionForm from "./ShippingOptionForm";
import PaymentOptionForm from "./PaymentOptionForm";
import CreditDetailsForm from "./CreditDetailsForm";
import SimplePayOpForm from "./SimplePayOpForm";

@inject((store) => {
  const { myShopApiStore, ifaceMngr } = store;
  return { myShopApiStore, ifaceMngr };
})

@observer
class Payment extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             isShippingOption: true,
             currentStep: 1
            //  isPaymentForm: false
        }
    }
    
    setShippingOption = (shippingOption) => {
        this.setState({isShippingOption: shippingOption});
    }

    getFormattedPrice = () => {
        const { cartPrice } = this.props.myShopApiStore;
        const curr = "₪";
        return(
            <NumberFormat
                  value={cartPrice}
                  prefix={curr}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  displayType={"text"}
                />
        )
    }
    
    renderForm = () => {
        return(
            <>
                <ShippingOptionForm 
                            setShippingOption={this.setShippingOption}
                            currentStep={this.state.currentStep}
                            nextStep={this.nextStep}
                />
                {
                    this.state.isShippingOption
                    ? <PaymentOptionForm currentStep={this.state.currentStep} prevStep={this.prevStep} nextStep={this.nextStep}/>
                    : <SimplePayOpForm currentStep={this.state.currentStep} prevStep={this.prevStep} nextStep={this.nextStep}/>
                }
                
                <CreditDetailsForm currentStep={this.state.currentStep} prevStep={this.prevStep} nextStep={this.nextStep}/> 
            </>
            
        )
    }

    nextStep = () => {
        if(this.state.currentStep < 3){
            this.setState({currentStep: this.state.currentStep + 1});

        }
    }

    prevStep = () => {
        //reset date,from,to of deliveryData & pickupData---------------
        if(this.state.currentStep === 2){
            const { deliveryData, pickupData } = this.props.myShopApiStore.orderData;
            this.props.myShopApiStore.setOrderData('deliveryData', {...deliveryData, date: '', from: '', to: ''})
            this.props.myShopApiStore.setOrderData('pickupData', {...pickupData, date: '', from: '', to: ''})
        }
        //-----
        
        if(this.state.currentStep > 1){
            this.setState({currentStep: this.state.currentStep - 1});
        }
    }
    render() {
        const { shopData, mainColor } = this.props.myShopApiStore;        
        return (
            <div className={style.Container}>
                <div className={style.PriceInfo} style={{backgroundColor: mainColor}}>
                    <div className={style.PriceTitle}>סה"כ לתשלום: {this.getFormattedPrice()}</div>
                    <div className={style.PriceDescription}>
                      {shopData.paymentDescription}
                    </div>
                </div>
                {/* <button onClick={this.nextStep}>המשך</button> */}
                {/* <button onClick={this.prevStep}>חזור</button> */}
                {/* <div>step: {this.state.currentStep}</div> */}
                {/* <div>isShipping: {this.state.isShippingOption ? "true" : "false"}</div> */}
                <div>{this.renderForm()}</div>
            </div>
        )
    }
}

export default Payment
