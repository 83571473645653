import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Basket from './Basket';
import HistItem from './HistItem';
import Empty from "../Empty";
import { v4 as uuidv4 } from 'uuid';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class StoredHistory extends React.Component {
    constructor(props) {
        super(props)
    
        this.state = {
             basketVisible: true,
             interval: null,
             times: null,
        }
    }
    
    closeBasket = () => {
        this.setState({basketVisible: false})
    }

    showBasket = () => {
        this.setState({basketVisible: true})
    }

    handleClick = async () => {
        const { getOrderHistoryResp, setOrdersHistory } = this.props.myShopApiStore;
        
        const resp = await getOrderHistoryResp()

        if(resp && resp.errorCode === '0'){
            setOrdersHistory(resp.data.orders);
        }
    }

    handleScroll = async e => {
        const { scrollHeight, scrollTop, clientHeight } = e.target;
        const { getOrderHistoryResp, setOrdersHistory } = this.props.myShopApiStore;
        // console.log(e)
        // console.log('scrollHeight:', e.target.scrollHeight);
        // console.log('scrollTop:', Math.floor(e.target.scrollTop));
        // console.log('clientHeight:', e.target.clientHeight);
        
        if(scrollHeight - Math.floor(scrollTop) === clientHeight ||
                scrollHeight - Math.ceil(scrollTop) === clientHeight){
            const isFirstPage = false;
            
            const histResp = await getOrderHistoryResp(isFirstPage)
            if(histResp && histResp.errorCode === '0'){
                setOrdersHistory(histResp.data.orders);
            }
        }
    }

    componentWillUnmount() {
        this.state.interval && clearInterval(this.state.interval);
    }
    
    
    componentDidMount = async () => {
        const { ordersHistory, setOrdersHistory, getOrderHistoryResp } = this.props.myShopApiStore;

        ///////////
        const { getOrderHistoryTimeResp } = this.props.myShopApiStore;
        let timesResp = await getOrderHistoryTimeResp();
        let times = (timesResp && timesResp.length) ? [...timesResp] : null;
        this.setState({times});

        const interval = setInterval(async () => {
            timesResp = await getOrderHistoryTimeResp();
            times = (timesResp && timesResp.length) ? [...timesResp] : null;
            this.setState({times})
        }, 30000);
        
        this.setState({interval})

        if(ordersHistory && !ordersHistory.length){
            const isFirstPage = true;

            const resp = await getOrderHistoryResp(isFirstPage)
            if(resp && resp.errorCode === '0'){
                setOrdersHistory(resp.data.orders);
            }
        }   
    }
    
    getShippingStatus = (orderId) => {
        let shippingStatus = null;
        this.state.times && this.state.times.forEach(time => {
            if(orderId === time.orderId){
                shippingStatus = {...time};
            }
        })
        return shippingStatus;
    }

    render() {
        const { ordersHistory, resetOrdersHistory, setHistoryPage } = this.props.myShopApiStore;
        // console.log("ordersHistory::>>--------->",ordersHistory)
        return (
            <div className={style.container}>
                {
                    ordersHistory.length
                    ?
                        null
                    :
                        <Empty closeBasket={this.closeBasket}/>
                }
                {/* <button onClick={this.handleClick}>FETCH DATA</button> */}
                {/* <button onClick={() => {resetOrdersHistory(); setHistoryPage(0)}}>RESET DATA</button> */}
                <div id="" className={style.TopDiv}>
                    <div className={style.ESpace}></div>
                    <div className={style.HistList} onScroll={this.handleScroll}>
                        {
                            ordersHistory.length
                            ?
                                ordersHistory.map((order, idx) => 
                                    <React.Fragment key={uuidv4()}> 
                                        <HistItem order={order}
                                            getShippingStatus={this.getShippingStatus}
                                            showBasket={this.showBasket}
                                            basketVisible={this.state.basketVisible}
                                        />
                                    </React.Fragment>)
                            :
                                null
                        }
                    </div>
                    <div className={style.contBasket}>
                    {
                        this.state.basketVisible
                        ? 
                            <div className={style.Basket}>
                                <div className={style.closeBasket} onClick={this.closeBasket}>✕</div>
                                <Basket />
                            </div>
                        :
                            ''
                    }
                    </div>
                </div>
            </div>
        )
    }
}

export default StoredHistory;
