import React from "react";
import NumberFormat from "react-number-format";
import style from "./style.module.css";

const SubForm1 = ({
  currentStep,
  handleChange,
  shopProductOptions,
  selectedProductOption,
  optionsDescription,
  optionsPaid
}) => {
  if (currentStep !== 1) {
    return null;
  }

  const curr = "₪";
  return (
    <div className={style.Container}>
      <div className={style.OptionsDescription}>{optionsDescription}</div>
      <div className={style.ItemOptionList}>{shopProductOptions.map((po, idx) => {
          return (
            <div key={idx} className={style.ItemOption}>
              <div className={style.WrapBtnAndName}>
                <input
                  className={style.ItemOptionRadioBtn}
                  type="radio"
                  value={po.productOption.name}
                  name="selectedProductOption"
                  checked={selectedProductOption.name === po.productOption.name}
                  onChange={handleChange}
                />
                <label className={style.ItemOptionName}>{po.productOption.name}</label>
              </div>
              <span className={style.ItemOptionPrice}>
                {(!po.price || optionsPaid === 0) ? (
                  "ללא עלות"
                ) : (
                  <NumberFormat
                    value={po.price}
                    prefix={curr}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    displayType={"text"}
                  />
                )}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubForm1;
