import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})

@observer
class MenuItem extends React.Component {
    render() {
        const { selectedCategoryIdx, searchValue, setSearchValue, getItemList } = this.props.myShopApiStore;
        const { item, index } = this.props
        return (
            <div className={style.Container}>
                <div className={style.TopDiv} data-ind={index} data-code={item.codename} onClick={()=>{getItemList(index,true); setSearchValue('')}}>
                    <div className={selectedCategoryIdx==index && !searchValue ? `${style.ItemIcon} ${style.ItemIconSelected}`:style.ItemIcon}>
                        <img className={style.ItemImg} src={item.icon} alt={item.name}/>
                    </div>
                    <div className={style.ItemName}>
                        {item.name}
                    </div>
                </div>
            </div>
        )
    }
}

export default MenuItem;
