import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import ReactTooltip from 'react-tooltip';

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})

@observer
class PackItem extends React.Component {
    render(){
        const { packItem, handleAddPackItemClick } = this.props;
        return(
            <div className={style.Container} style={{border: packItem.isReadyToOrder ? "1px solid #ff8787" : "1px solid #e6e6e6",
                                                    backgroundColor: packItem.isReadyToOrder && "rgba(244, 230, 230, 0.16)"}}>
                <div className={style.RightElements}>
                    {/* <div className={style.Checked}>
                        <input type="checkbox" checked={packItem.isReadyToOrder} onChange={() => handleCheckboxChange(packItem.id)}/> 
                    </div> */}
                    <div className={style.ItemImage}>
                        <img
                            width="100px"
                            src={packItem.item.product ? packItem.item.product.image : ""}
                            alt={packItem.item.product ? packItem.item.product.description : ""}
                        />
                    </div>
                </div>
                <div className={style.MidElements}>
                    <div className={style.ItemName} title={packItem.item.product.name}>{packItem.item.product.name}</div>
                    <div className={style.ItemButtons}>
                        <div className={style.ItemUnitsBtn}>יח'</div>
                        <div 
                            className={style.ItemAddBtn}
                            onClick={() => handleAddPackItemClick(packItem, this.props.stepNum)}
                            >
                            
                            {!packItem.isReadyToOrder ? "+ הוסף" : packItem.item.shopProductOptions.length ? "עדכן" : "התווסף"}
                        </div>
                    </div>
                </div>
                <div className={style.LeftElements}>
                    <div className={style.MoreInfoTooltip}>
                        {packItem.item.product.description && 
                            <>
                            <div data-tip={packItem.item.product.description} data-for={`moreInfo_${packItem.id}`}>מידע נוסף</div>
                            <ReactTooltip 
                                className={style.Tooltip}
                                id={`moreInfo_${packItem.id}`}
                                multiline={true}
                                data-html={true}
                                insecure={true} 
                                type='light'
                                border={true}
                                effect='solid'
                                place="right" 
                            />
                                {/* <span>{packItem.item.product.description}</span> */}
                            {/* </ReactTooltip> */}
                            </>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default PackItem;
