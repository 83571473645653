import React, { createRef } from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import APILoader from '../../APILoader';
import OrderTimeModal from '../OrderTimeModal';
import ThanksModal from '../../ThanksModal';
import Geocode from 'react-geocode';
import { getDistance } from 'geolib';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
  } from "react-google-maps";
import PostageModal from './PostageModal';
import { Media } from 'react-bootstrap';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.setLanguage('he')

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class MapModal extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {  
                        distOutOfRange: false,
                        postageModalIsOpen: false,
                        // thanksModalIsOpen: false,
                        apiLoaderIsOpen: false,
                        orderTimeModalIsOpen: false,
                        isMobile: window.innerWidth <= 850,
                    }
        
        this.modalStyle = {
            content : {
                backgroundColor: 'unset',
                top: '50%',
                left: '51%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width:  this.state.isMobile ? '100%' : '65%',
                height: '90%',
                maxHeight: '100%',
                border: 'unset',
                overflow: 'auto',
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.87)",
                zIndex: 5,
            }
        }
    }

    updateIsMobile = () => {
        this.setState({ isMobile: window.innerWidth <= 850 });
      }

    componentDidMount() {
        window.addEventListener('resize', this.updateIsMobile); 
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateIsMobile);
    }

    getAddress = () => {
        const { streetName, streetNumber, city } = this.props.myShopApiStore.userParams;
        return `${streetName} ${streetNumber}, ${city} `;
    }

    onMarkerDargEnd = async (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        
        const { setDeliveryLatLng, setUserParams} = this.props.myShopApiStore;        

        await Geocode.fromLatLng(newLat, newLng)
        .then(response => {
            if(response.results){
                if(response.results.length){
                    setDeliveryLatLng({ lat: newLat, lng: newLng })
                    setUserParams('streetNumber', response.results[0].address_components[0].long_name)
                    setUserParams('streetName', response.results[0].address_components[1].long_name)
                    setUserParams('city', response.results[0].address_components[2].long_name)
                }
            } 
        })
        .catch(err => {
            setDeliveryLatLng({ lat: newLat, lng: newLng });
            console.log("ERROR::",err)
        })
        
    }

    getLat = () => {
        const { getDeliveryLatLng } = this.props.myShopApiStore;
        return getDeliveryLatLng() ? getDeliveryLatLng().lat : 32.085300;
    }

    getLng = () => {
        const { getDeliveryLatLng } = this.props.myShopApiStore;
        return getDeliveryLatLng() ? getDeliveryLatLng().lng : 34.781769;
    }

    MapWithAMarker = withScriptjs(withGoogleMap(props =>
        
        <GoogleMap
        defaultZoom={12}
        defaultCenter={{ lat: this.getLat(), lng: this.getLng() }}
        >
        <Marker
            position={{ lat: this.getLat(), lng: this.getLng() }}
            draggable={true}
            onDragEnd={this.onMarkerDargEnd}
        />

        </GoogleMap>
        
    ));

    handleUpdateAddressClick = () => {
        this.calcPostage();
        // this.setState({ distance });
        this.openPostageModal();
    }

    calcPostage = () => {
        const { getShopLatLng, shop, getDeliveryLatLng,
                setDeliveryCost, setDeliveryLatLng, setOrderData } = this.props.myShopApiStore;
        const deliveryZonesAmount = shop.deliveryZones ? shop.deliveryZones.length : 0;

        let distance = 0;

        if(!getDeliveryLatLng() || !getShopLatLng()){ //handle fetching error of shop/user locations! (addrs -> lat/lng)
            this.setState({distOutOfRange: true});
        }
        else{
            const {shopLat, shopLng} = getShopLatLng()
            const { lat, lng } = getDeliveryLatLng();
    
            //checks distance in meters
            distance = getDistance(
                                { latitude: shopLat, longitude: shopLng },
                                { latitude: lat, longitude: lng }
                            );
            
            // convert meters to km
            distance /= 1000;
    
            for(let i=0; i<deliveryZonesAmount ; i++){
                const { deliveryCost, from, to}  = shop.deliveryZones[i];
                if(distance >= from && distance < to){
                    setDeliveryCost(deliveryCost);
                    setDeliveryLatLng({ lat, lng });
                    if(this.state.distOutOfRange){
                        this.setState({distOutOfRange: false})
                    }
                    break;
                }
            }
    
            //distance out of range
            if(deliveryZonesAmount){
                if(shop.deliveryZones[shop.deliveryZones.length-1].to <= distance){
                    this.setState({distOutOfRange: true});
                    distance = 0;
                }
            }
            
            //calc delivery cost for 'katif' shop (old interface)
            if(!deliveryZonesAmount){
                if(distance >= 0 && distance < shop.deliveryRadius){
                    setDeliveryCost(shop.deliveryCost);
                    setDeliveryLatLng({ lat, lng });
                    
                    if(this.state.distOutOfRange){
                        this.setState({distOutOfRange: false})
                    }
                }
                else{
                    this.setState({distOutOfRange: true})
                    distance = 0;
                }
            }
        }

        setOrderData('distance', Math.floor(distance));
    }

    openPostageModal = () => {
        this.setState({postageModalIsOpen: true})
    }

    closePostageModal = () => {
        this.setState({postageModalIsOpen: false})
    }

    // openThanksModal = () => {
    //     this.setState({thanksModalIsOpen: true})
    // }

    // closeThanksModal = () => {
    //     this.setState({thanksModalIsOpen: false})
    //     this.props.closeModal();
    // }
    
    openAPILoader = () => {
        this.setState({apiLoaderIsOpen: true})
    }

    closeAPILoader = () => {
        this.setState({apiLoaderIsOpen: false})
    }

    closeOrderTimeModal = () => {
        this.setState({orderTimeModalIsOpen: false})
    }

    openOrderTimeModal = () => {
        this.setState({orderTimeModalIsOpen: true})
    }

    render() {
        const { modalIsOpen, closeModal } = this.props;
        const { orderData, userParams, mainColor } = this.props.myShopApiStore;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen}>
                <div className={style.TopContent}>
                    <div className={style.Title}>
                        <span>הזז את הסמן לכתובת שלך, ואז לחץ על עדכן כתובת</span>
                    </div>
                    <div className={style.Address}>
                        <span>{userParams.streetName ? this.getAddress() : "לא נמצאו תוצאות"}</span>
                    </div>
                </div>
                <this.MapWithAMarker
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                    loadingElement={<div style={{ height: '100%' }} />}
                    containerElement={<div style={{ height: '60%' }} />}
                    mapElement={<div style={{ height: '100%',borderRadius: '9px' }} />}
                />
                <div className={style.BottomContent}>
                    <div className={style.AddressUpdateBtn} onClick={this.handleUpdateAddressClick} style={{backgroundColor: mainColor}}>
                        <span>עדכן כתובת</span>
                    </div>
                    <div className={style.BackBtn} onClick={closeModal}>
                        <span>חזור</span>
                    </div>
                </div>
                
                <div>
                    {
                        this.state.postageModalIsOpen && <PostageModal  modalIsOpen={this.state.postageModalIsOpen}
                                                                        closeModal={this.closePostageModal}
                                                                        closeMapModal={closeModal}
                                                                        address={this.getAddress()}
                                                                        distOutOfRange={this.state.distOutOfRange}
                                                                        nextStep={this.props.nextStep}
                                                                        openAPILoader={this.openAPILoader}
                                                                        closeAPILoader={this.closeAPILoader}
                                                                        openOrderTimeModal={this.openOrderTimeModal}
                                                                        closeMapModal={closeModal}
                                                                        openThanksModal={this.props.openThanksModal} 
                                                                        closeThanksModal={this.props.closeThanksModal}
                                                                        thanksModalIsOpen={this.props.thanksModalIsOpen}    
                                                                        
                                                                        />
                    }
                </div>
                <div className={style.Modals}>
                    <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                    <OrderTimeModal closeModal={this.closeOrderTimeModal} modalIsOpen={this.state.orderTimeModalIsOpen} orderType={orderData.isDelivery ? 'משלוחים' : 'באיסוף עצמי'}/>
                    {/* <ThanksModal closeModal={this.closeThanksModal} modalIsOpen={this.state.thanksModalIsOpen} /> */}
                </div>
            </Modal>
        )
    }
}

export default MapModal;