import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import style from './style.module.css';
import StoredHistory from "./StoredHistory";

@inject(store => {
    const { myShopApiStore } = store;
    return { myShopApiStore };
})


@observer
class History extends React.Component {
    // hist = this.props.myShopApiStore.getHistory();
    componentWillUnmount = () => {
        const { resetOrdersHistory, setBasketHistory, resetHistoryPage } = this.props.myShopApiStore;
        resetOrdersHistory();
        setBasketHistory(null);
        resetHistoryPage();
    }
    
    render() {
        const { ordersHistory } = this.props.myShopApiStore;
        return (
        <div className={style.container}>
            <div className={style.Header}>היסטוריית הזמנות</div>
            
                {/* this.hist?
                <StoredHistory/>
                : <Empty /> */
                /* ordersHistory.length
                ?
                    <StoredHistory/>
                : 
                    <Empty /> */}
            
            <StoredHistory/>
        </div>
        )
    }
}

export default History;
