import React from "react";
import NumberFormat from "react-number-format";
import ReactTooltip from 'react-tooltip';
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject((store) => {
  const { myShopApiStore } = store;
  return { myShopApiStore };
})
@observer
class CartItem extends React.Component {
  constructor(props) {
    super(props)
    
    this.inputRef = React.createRef();
    this.state = {
       comment: ''
    }
  }

  handleCommentChange = () => {
    if(this.inputRef.current){
      this.props.cartItem.comment = this.inputRef.current.value;
    }
  }

  render() {
    const { removeCartItem, openCartItemForm, getItemPrice, getCartPackItemPrice } = this.props.myShopApiStore;
    const { cartItem, index, handleCheckboxChange } = this.props;
    const product = cartItem.item.product ? cartItem.item.product : null;
    const curr = "₪";

    const iNew = <div className={`${style.iFlags} ${style.iNew}`}>חדש!</div>;
    const iSale = <div className={`${style.iFlags} ${style.iSale}`}>במבצע!</div>;
    const iSeason = <div className={`${style.iFlags} ${style.iSeason}`}>עונה!</div>;

    const hasProductOption = cartItem.item.shopProductOptions ? cartItem.item.shopProductOptions.length : false;
    const isEditableItem = hasProductOption || cartItem.item.shopToppings.length; 
    const isCartPackItem = cartItem.item.product.productType === "pack";
    
    return (
      <div className={style.Container}>
        <div className={style.MidRightElements}>
          <div className={style.ItemRightElements}>
            <input className={style.ItemCheckbox}
                  type="checkbox"
                  checked={cartItem.isReadyToOrder}
                  onChange={(e) => handleCheckboxChange(e, cartItem.id)}
            />
            <div>
              <LazyLoadImage
                effect='blur'
                className={style.ItemImage}
                src={product ? product.image : ""}
                alt={product ? product.description : ""}
              />
              <div className={style.ItemFlags}>
                { cartItem.item.isNew ? iNew : '' }
                { cartItem.item.isSale ? iSale : '' }
                { cartItem.item.isSeason ? iSeason : '' }
              </div>
              {/* <div className={style.UserCommentForm}>
                <div className={style.Label}>
                  <span>הוסף הערה:</span>
                </div>
                <div className={style.Input}>
                  <input type='text' value={this.state.comment} onChange={this.handleCommentChange}/>
                </div>
              </div> */}
            </div>
            <div className={style.ItemDetails}>
              <div className={style.ItemName}>{cartItem.item.product.name}</div>
              <div className={style.ItemQuantity}>
                {
                  cartItem.unitType
                  ? 
                    cartItem.unitType.type === "kg"
                    ? 
                      `${cartItem.quantity} ק"ג`
                    :
                      `${cartItem.quantity} יח'`
                  :
                    cartItem.item.unitTypes[0].type === "kg"
                    ? 
                      `${cartItem.quantity} ק"ג`
                    :
                      `${cartItem.quantity} יח'`
                }
              </div>
            </div>
          </div>
          <div className={style.Comment}>
                <div className={style.Title} onClick={() => {this.inputRef.current && this.inputRef.current.focus()}}>
                  הוסף הערה:
                </div>
                <div className={style.InputText}>
                  <input type='text' value={cartItem.comment} ref={this.inputRef} onChange={this.handleCommentChange}/>
                </div>
          </div>
        </div>
        <div className={style.ItemLeftElements}>
          <div className={style.ItemPrice}>
            <NumberFormat
              value={isCartPackItem ? getCartPackItemPrice(cartItem) : getItemPrice(cartItem)}
              prefix={curr}
              decimalScale={2}
              fixedDecimalScale={true}
              displayType={"text"}
            />
          </div>
          <div className={style.ItemBtnsAndTooltip}>
            <div className={style.MoreInfoTooltip}>
              {cartItem.item.product.description && 
                  <>
                    <div data-tip={cartItem.item.product.description} data-for={`moreInfo_${cartItem.id}`}>
                      מידע נוסף
                    </div>
                    <ReactTooltip 
                      className={style.Tooltip}
                      place="right" 
                      multiline={true}
                      data-html={true}
                      insecure={true} 
                      id={`moreInfo_${cartItem.id}`} 
                      type='light' 
                      border={true} 
                      effect='solid' 
                    />
                  </>
              }
            </div>
            <div className={style.EditButton}>
              {(isEditableItem || isCartPackItem) ? (
                <button onClick={() => openCartItemForm(cartItem, isCartPackItem, true)}>עדכן מוצר</button>
              ) : null}
            </div>
            <div className={style.RemoveButton}>
              <button onClick={() => removeCartItem(index)}>הסר מהסל</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CartItem;
