import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import APILoader from 'Components/MainScreen/AppBody/APILoader';
import ValidSMSModal from 'Components/MainScreen/AppBody/ValidSMSModal';
import ThanksModal from 'Components/MainScreen/AppBody/ThanksModal';


@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class PostageModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            apiLoaderIsOpen: false,
            smsModalIsOpen: false,
        }

        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                width: '365px',
                height: '260px',
                maxHeight: '300px'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                zIndex: 10
            }
        }
    }

    preLoadApi = async () => {
        const { createSessionResp, makePreorderResp, creditUniqueId} = this.props.myShopApiStore;

        await makePreorderResp();
        await createSessionResp(creditUniqueId);
    }

    handleCountinueClick = async () => {
        const { closeModal, closeMapModal, nextStep } = this.props;
        const { calcTotalCartPrice, orderData, registerUser } = this.props.myShopApiStore;

        calcTotalCartPrice();

        if(orderData.isCredit){ //payment with credit card!
            closeModal(); //closing postage modal
            closeMapModal();

            // const { createSessionResp } = this.props.myShopApiStore;
            // await createSessionResp();
            await this.preLoadApi();
            nextStep()

        }
        else{ //payment to delivery guy!
            this.openAPILoader()
            const regResp = await registerUser();
            this.closeAPILoader();

            if(regResp.data){ //valid token
                await this.handleMakeOrderCall();
            }
            else{ //invalid token
                this.openSmsModal();
            }
        }
    }

    handleMakeOrderCall = async () => { //make order (-when the payment is to delivery guy!-)
        const { closeModal, openOrderTimeModal,
            openThanksModal,  closeMapModal } = this.props;
        const { makeOrderResp } = this.props.myShopApiStore;

        this.openAPILoader()
        const resp = await makeOrderResp();
        this.closeAPILoader();

        closeModal(); //closing postage modal
        if(resp.data.errorCode !== '0'){
            if(resp.data.errorCode === 'DATE_ERROR'){
                openOrderTimeModal();
            }
            else{
                console.log('ERROR_CODE:', resp.data.errorCode);
            }
        }
        else{
            closeMapModal();
            openThanksModal();
        }
    }
    
    openAPILoader = () => {
        this.setState({apiLoaderIsOpen: true})
    }

    closeAPILoader = () => {
        this.setState({apiLoaderIsOpen: false})
    }

    openSmsModal = () => {
        this.setState({smsModalIsOpen: true});
    }

    closeSmsModal = () => {
        this.setState({smsModalIsOpen: false});
    }

    render() {
        
        const { modalIsOpen, closeModal, distOutOfRange } = this.props;
        const { getShopLatLng } = this.props.myShopApiStore;
        return (

            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={closeModal}>
                <APILoader modalIsOpen={this.state.apiLoaderIsOpen} />
                <ValidSMSModal modalIsOpen={this.state.smsModalIsOpen} closeModal={this.closeSmsModal}
                                handleMakeOrderCall={this.handleMakeOrderCall} action='Delivery' nextStep={this.props.nextStep}
                />
                <ThanksModal closeModal={this.props.closeThanksModal} modalIsOpen={this.props.thanksModalIsOpen} />

                <div className={style.Container}>
                    <div className={style.CancleXBtn}>
                        <span onClick={closeModal}>✕</span>
                    </div>
                    <div className={style.TextContnet}>
                        <div className={style.Title}>
                            <span>שים לב!</span>
                        </div>
                        <div className={style.TowRowText}>
                            {
                                distOutOfRange
                                ?
                                    <>
                                        <span>לא ניתן לבצע משלוח</span>
                                        <span>לכתובת המבוקשת</span>
                                        {!getShopLatLng() && <span style={{color: '#FF0000', fontSize: '12px', fontWeight: '600'}}> שגיאה! לא ניתן למצוא מיקום חנות.</span>}
                                    </>
                                :
                                    <>
                                        <span>עלות דמי משלוח לכתובת</span>
                                        <span>שהזמנת הינה</span>
                                    </>
                            }
                        </div>

                        <div className={style.Price}>
                        {
                            distOutOfRange
                            ?
                                <>
                                    <span style={{color: '#FFFFFF'}}>.</span>
                                    <span style={{color: '#FFFFFF'}}>.</span>
                                </>
                            :
                                <>
                                    <span>₪</span>
                                    <span>{this.props.myShopApiStore.deliveryCost.toFixed(2)}</span>
                                </>
                        }
                        </div>
                        
                    </div>
                    <div className={style.ContinueBtn}>
                        <span onClick={distOutOfRange ? closeModal : this.handleCountinueClick}>{distOutOfRange ? 'חזור' : 'המשך'}</span>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default PostageModal;