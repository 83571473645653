import React from 'react';
import { observer, inject } from 'mobx-react';
// import ReactDOM from 'react-dom';
import searchBg from '../../../assets/search.png';
import style from './style.module.css';
import TopMenuLayout from 'Components/layouts/TopMenuLayout';

@inject(store => {
    const { ifaceMngr, myShopApiStore } = store;
    return { ifaceMngr, myShopApiStore };
})

@observer
class TopMenu extends React.Component {
    render() {
        const { closeStore, userToken, userParams, shopsData, userId} = this.props.myShopApiStore;
        return <TopMenuLayout activeTopMenu={this.props.ifaceMngr.activeTopMenu}
                                setActiveTopMenu={this.props.ifaceMngr.setActiveTopMenu}
                                closeStore={closeStore}
                                userParams={userParams}
                                userToken={userToken}
                                shopsData={shopsData}
                                userId={userId}
                                />
        
    }
}

export default TopMenu;
