const api = {
    my_shop_api: {
        url: process.env.REACT_APP_MY_SHOP_URL,
        encrypt: process.env.REACT_APP_MY_SHOP_KEY,
        shopId: process.env.REACT_APP_MY_SHOP_ID,
        mStatus: process.env.REACT_APP_MY_SHOP_MAINTENANCE,
        mText: process.env.REACT_APP_MY_SHOP_MTEXT,
        debug: process.env.REACT_APP_MY_SHOP_DEBUG
    },
    account_kit: {
        appId: "262140850978884",
        version: "v1.3",
    },
    shop_api: {
        url: process.env.REACT_APP_URL_API,
        encrypt: "ITkhdA8mOBmrVDnfCHox5Zsvctj5KaihYIdmscTYQweQfg==",
        socketUrl: process.env.REACT_APP_URL_SOCKET,
        shopId: process.env.shopId
    }
};

export { api };