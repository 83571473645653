import React from 'react';
import { observer, inject } from 'mobx-react';
import style from './style.module.css';
import Modal from 'react-modal';
import happy from '../../../../assets/happy.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

@inject(store => {
    const { myShopApiStore, ifaceMngr } = store;
    return { myShopApiStore, ifaceMngr };
})

@observer
class ThanksModal extends React.Component {
    constructor(props) {
        super(props);
        this.modalStyle = {
            content : {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                // width: '30%',
                width: '365px',
                height: '275px'
                
            },
            overlay: {
                backgroundColor: "rgb(0, 0, 0, 0.65)",
                
            }
        }
    }
    
    handleCodeChange = (e) => {
        const { name, value } = e.target;

        if(value.length && !value.match(/\D/)){
            this.setState({[name]: value.slice(-1)});
        }
    }

    handleClick = () => {
        this.props.closeModal();
        this.props.myShopApiStore.clearReadyToOrderCartItems();
        this.props.ifaceMngr.setActiveTopMenu("ShopMenu");
    }
    
    render() {
        const { modalIsOpen } = this.props;
        const { shop } = this.props.myShopApiStore;
        return (
            <Modal style={this.modalStyle} isOpen={modalIsOpen} onRequestClose={this.handleClick}>
                <div className={style.ModalContainer}>
                    <div className={style.CloseBtn}>
                        <span onClick={this.handleClick}>✕</span>
                    </div>
                    <div className={style.Body}>
                        <LazyLoadImage effect='blur' src={happy} width='80px'/>
                        <div className={style.ModalTitle}>
                            <span>{`תודה שהזמנת מ${shop.name}!`}</span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default ThanksModal;